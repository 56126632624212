import { useState, useEffect } from 'react';
import axios from 'axios';
import { renderSummaryWithReferences } from '../Summary/summaryUtils';
import moment from 'moment';

const EmpathAnalytics = require("../../../EmpathAnalytics");
const useClientData = (selectedUser, startDate, endDate, setExpandedJournalIndex) => {
  const [userJournals, setUserJournals] = useState(null);
  const [healthData, setHealthData] = useState(null);
  const [userSummary, setUserSummary] = useState(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [journalIdMap, setJournalIdMap] = useState({});
  const [isMoodChartLoading, setIsMoodChartLoading] = useState(false);
  const [isJournalLoading, setIsJournalLoading] = useState(false); // Add this line
  const [emotionData, setEmotionData] = useState([
    { category: "Alert/Anxious", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Excited/Elated", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Pleased", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Content/Relaxed", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Calm/Indifferent", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Depressed/Bored", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Dissapointed", feelingValue: 0.0, calculatedValue: 0.0 },
    { category: "Angry/Frustrated", feelingValue: 0.0, calculatedValue: 0.0 },
  ]);
  const [yearlyJournalData, setYearlyJournalData] = useState([]);
  const [hourlyJournalData, setHourlyJournalData] = useState([]);
  const [moodStabilityScore, setMoodStabilityScore] = useState(null);
  const [moodStabilityDataPoints, setMoodStabilityDataPoints] = useState(0);

  const retrieveClientJournalEntries = async (user, start, end) => {
    setIsJournalLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/retrieveClientJournalEntries`,
        {
          userId: user.id,
          startDate: start,
          endDate: end,
        },
        { withCredentials: true }
      );
      setUserJournals(response.data);
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'retrieve_journals_error');
      console.log(error);
      setUserJournals([]);
    } finally {
      setIsJournalLoading(false);
    }
  };

  const retrieveClientsAppleHealthData = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/appleHealth/getAllAppleHealthData`,
        {
          userId: id,
        },
        { withCredentials: true }
      );
      setHealthData(response.data.data);
    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'retrieve_apple_health_error');
      console.log(error);
      setHealthData(null);
    }
  };

  const retrieveGeneratedEmotionData = async (user, start, end) => {
        try {
            setIsMoodChartLoading(true); // Start loading
            const response = await axios.post(
            `${process.env.REACT_APP_BACK_END_URL}/api/moods/emotionData`,
            {
                userId: user.id,
                startDate: start,
                endDate: end,
            },
            { withCredentials: true }
        );
        setEmotionData(response.data.emotionData);
    } catch (error) {
        EmpathAnalytics.logEvents('error_message', 'retrieve_emotion_data_error');
        console.log(error);
        setEmotionData([]);
    } finally {
        setIsMoodChartLoading(false); // Stop loading
    }
};

  const generateSummary = async () => {
    setIsGeneratingSummary(true);
    setUserSummary(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/journals/generateClientJournalSummary`,
        {
          userId: selectedUser.id,
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        },
        { withCredentials: true }
      );
      const renderedSummary = renderSummaryWithReferences(response.data.summary || "", response.data.journalIdMap, userJournals, setExpandedJournalIndex);
      setUserSummary(renderedSummary);

    } catch (error) {
      EmpathAnalytics.logEvents('error_message', 'generate_summary_error');
      console.error("Error generating summary:", error);
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const retrieveYearlyJournalEntries = async (user) => {
    if (!user) return;
    const today = new Date();
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/retrieveClientJournalEntries`,
        {
          userId: user.id,
          startDate: oneYearAgo.toISOString().split('T')[0],
          endDate: today.toISOString().split('T')[0],
        },
        { withCredentials: true }
      );
      
      // Transform the data to include dates
      const transformedData = response.data.map(journal => ({
        date: journal.created_at ? new Date(journal.created_at).toISOString().split('T')[0] : 
              journal.entry_date ? new Date(journal.entry_date).toISOString().split('T')[0] : null,
        count: 1
      })).filter(entry => entry.date !== null);
      
      console.log('Transformed journal entries:', transformedData.slice(0, 5));
      setYearlyJournalData(transformedData);
    } catch (error) {
      console.log(error);
      setYearlyJournalData([]);
    }
  };

  const retrieveHourlyJournalEntries = async (user) => {
    if (!user) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/retrieveClientJournalEntries`,
        {
          userId: user.id,
          startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
        { withCredentials: true }
      );
      
      // Process the data to get hourly counts
      const hourlyData = Array(24).fill(0);
      response.data.forEach(journal => {
        const hour = moment(journal.created_at).hour();
        hourlyData[hour]++;
      });
      
      // Transform data for the heatmap
      const transformedData = hourlyData.map((count, hour) => ({
        hour,
        count,
      }));
      
      setHourlyJournalData(transformedData);
    } catch (error) {
      console.log(error);
      setHourlyJournalData([]);
    }
  };

  const fetchMoodStabilityScore = async () => {
    if (!selectedUser || !selectedUser.id) {
      console.log("No selected user or user ID");
      return;
    }
    
    console.log("Fetching mood stability for user:", selectedUser.id);
    console.log("Start date:", startDate.toISOString().split('T')[0]);
    console.log("End date:", endDate.toISOString().split('T')[0]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/clients/calculateMoodStability`,
        {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        },
        { withCredentials: true }
      );
      
      console.log("Mood stability response:", response.data);

      if (response.data.moodStabilityScore !== null) {
        setMoodStabilityScore(response.data.moodStabilityScore);
        setMoodStabilityDataPoints(response.data.dataPoints);
      } else {
        console.log("No mood stability score available:", response.data.message);
        setMoodStabilityScore(null);
        setMoodStabilityDataPoints(0);
      }
    } catch (error) {
      console.error("Error fetching mood stability score:", error.response?.data || error.message);
      setMoodStabilityScore(null);
      setMoodStabilityDataPoints(0);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      const fetchData = async () => {
        await retrieveClientJournalEntries(selectedUser, startDate, endDate);
        await retrieveClientsAppleHealthData(selectedUser.id);
        await retrieveGeneratedEmotionData(selectedUser, startDate, endDate);
        await retrieveHourlyJournalEntries(selectedUser);
        await fetchMoodStabilityScore();
      };
      fetchData();
    }
    retrieveYearlyJournalEntries(selectedUser);
  }, [selectedUser, startDate, endDate]);

  return {
    userJournals,
    healthData,
    userSummary,
    isGeneratingSummary,
    journalIdMap,
    emotionData,
    isMoodChartLoading,
    isJournalLoading,
    generateSummary,
    retrieveClientJournalEntries,
    retrieveClientsAppleHealthData,
    retrieveGeneratedEmotionData,
    yearlyJournalData,
    hourlyJournalData,
    retrieveYearlyJournalEntries,
    retrieveHourlyJournalEntries,
    moodStabilityScore,
    moodStabilityDataPoints,
    fetchMoodStabilityScore,
  };
};

export default useClientData;